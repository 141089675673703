import React from "react"
import { HomePage } from "./homePage/HomePage"
import { PageProps } from "gatsby"

export const Home = (props: PageProps) => {
  return (
    <>
      <HomePage />
    </>
  )
}

export default Home
